import Badge from "@mui/material/Badge";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import { CldUploadWidget } from "next-cloudinary";
import ListImage from "components/ListImage";
import TagInput from "./TagInput";

import React, { ReactElement, useState, useEffect } from "react";
import { mutate } from "swr";
import { range } from "lodash";

const NumberedTextField = ({ number, data }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [text, setText] = useState("");
  const [imageURL, setImageURL] = useState(data?.images?.[0]?.url || "");
  function handleTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setText(e.target.value);
    if (e.target.value.length == 0) setShowDetails(false);
    else if (e.target.value.length > 3) setShowDetails(true);
  }
  function handleBlur() {
    if (text.length > 0) {
      setShowDetails(true);
    } else setShowDetails(false);
  }
  return (
    <Stack>
      <Badge
        badgeContent={number}
        color="primary"
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
      >
        <TextField
          onChange={handleTextChange}
          onBlur={handleBlur}
          required
          fullWidth
          multiline
          defaultValue={data?.content}
          name={`item${number}`}
        />
      </Badge>
      {(showDetails || !!data?.content) && (
        <>
          <TextField
            margin="dense"
            label="Details?"
            placeholder={"Details?"}
            multiline
            defaultValue={data?.description}
            name={`itemdetails${number}`}
          />

          <TagInput
            name={"Link(s)"}
            value={data?.url}
            formOutput={`itemlink${number}`}
          />
          {imageURL && (
            <>
              <ListImage src={imageURL} />
              <input
                type="hidden"
                name={`itemimage${number}`}
                value={imageURL}
              />
              <Button
                onClick={() => setImageURL(null)}
                variant="outlined"
                color="error"
              >
                Remove Image
              </Button>
            </>
          )}
          {!imageURL && (
            <CldUploadWidget
              signatureEndpoint="/api/upload/sign"
              options={{
                multiple: false,
                sources: ["local", "url", "camera"],
                defaultSource: "local",
              }}
              //@ts-ignore
              onSuccess={(r, o) => setImageURL(r.info.secure_url)}
            >
              {({ open }) => (
                <Button variant="outlined" onClick={() => open()}>
                  Add Image?
                </Button>
              )}
            </CldUploadWidget>
          )}
        </>
      )}
    </Stack>
  );
};

export default function CreateList({
  loading,
  alert,
  open,
  closeCallback,
  edit,
  editData,
  canCreateList,
}: {
  loading: Function;
  alert: Function;
  button?: ReactElement;
  open: boolean;
  closeCallback: Function;
  edit?: boolean;
  editData?: any;
  canCreateList: boolean;
}) {
  const [showTitle, setShowTitle] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [draft, setDraft] = useState<any>(null);
  const [deleteDraftRerender, setDeleteDraftRerender] = useState(false);
  const [autosaveTimeout, setAutosaveTimeout] = useState<any>(null);
  const [tags, setTags] = useState<string[]>(
    editData?.Tags?.map((t) => t.name) || [],
  );
  useEffect(() => {
    setTags(editData?.Tags?.map((t) => t.name) || []);
  }, [editData?.Tags]);
  useEffect(() => {
    if (editData || !open) return;
    fetch("/api/list/draft/get")
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert({ status: "error", message: data.error });
        } else {
          if (!data.draft) return;
          const draft = JSON.parse(data.draft.data);
          setDraft(draft);
          setShowDescription(!!draft.description);
          setShowTitle(!!draft.title);
          if (draft.tags) setTags(draft.tags);
        }
      });

    return;
  }, [open]);

  function autoSave(e: React.FormEvent<HTMLFormElement>) {
    if (autosaveTimeout || editData) return;
    setAutosaveTimeout(
      setTimeout(() => {
        alert({
          status: "info",
          message: "Auto saving draft...",
          autoHideDuration: 1000,
        });
        saveDraft(null);
        setAutosaveTimeout(null);
      }, 30000),
    );
  }

  function saveDraft(e: React.MouseEvent<HTMLButtonElement>) {
    const form = document.forms[0];
    const formData = new FormData(form);
    let data = Object.fromEntries(formData.entries());
    let draftData = {
      title: data.title,
      description: data.description,
      tags: data.tags,
      ListItems: [
        {},
        ...range(1, 6).map((i) => {
          return {
            content: data[`item${i}`],
            description: data[`itemdetails${i}`],
            url: data[`itemlink${i}`],
            images: [{ url: data[`itemimage${i}`] }],
          };
        }),
      ],
    };
    fetch("/api/list/draft/save", {
      method: "POST",
      body: JSON.stringify({ data: draftData }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((r) => {
      if (r.status == 200) {
        //alert({ status: "success", message: "Draft saved!" });
      } else if (r.status == 204) {
        //empty draft detected
      } else {
        alert({ status: "error", message: "Error saving draft" });
      }
    });
  }
  function deleteDraft() {
    setDraft(null);
    fetch("/api/list/draft/delete");
    setDeleteDraftRerender(true);
    loading(true);
    setTimeout(() => {
      loading(false);
      setDeleteDraftRerender(false);
    }, 500);
    setShowTitle(false);
    setShowDescription(false);
    setTags([]);
  }

  function save(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const form = document.forms[0];
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());
    if (!!editData) data.id = editData.id;
    loading(true);
    fetch("/api/list/create", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert({ status: "error", message: data.error });
        } else {
          alert({ status: "success", message: "List created!" });
          mutate("/api/list/mine");
          closeCallback();
          fetch("api/list/draft/delete");
        }
      })
      .finally(() => {
        loading(false);
      });
  }

  return (
    <div>
      <Dialog open={open} fullWidth onClose={() => closeCallback()}>
        {!canCreateList && !editData && (
          <Alert severity="error">
            You've already created a list this week and can't post a new one
            yet. You can save this list as a draft and post it later.
          </Alert>
        )}
        {draft && !editData && (
          <Alert severity="info">
            This is a draft of an earlier list you started.
            <Button
              onClick={() => {
                deleteDraft();
              }}
              fullWidth
              sx={{ mt: 3 }}
              variant="outlined"
            >
              Delete draft
            </Button>
          </Alert>
        )}
        <DialogTitle>Five things you love this week</DialogTitle>
        <form id="listForm" onSubmit={save} onKeyDown={autoSave}>
          <DialogContent>
            {!deleteDraftRerender && (
              <div>
                <Stack spacing={2} mb={4}>
                  {range(1, 6).map((i) => (
                    <NumberedTextField
                      number={i}
                      key={i}
                      data={
                        editData
                          ? editData?.ListItems.find((li) => li.order == i)
                          : draft
                          ? draft?.ListItems?.[i]
                          : null
                      }
                    />
                  ))}
                </Stack>
                <hr />
                <Stack spacing={1}>
                  <Link
                    mt={3}
                    href="#"
                    underline="always"
                    onClick={() => setShowTitle(!showTitle)}
                  >
                    Title?
                  </Link>
                  {(showTitle || !!editData) && (
                    <TextField
                      fullWidth
                      name="title"
                      defaultValue={editData?.title || draft?.title}
                    />
                  )}
                  <Link
                    href="#"
                    underline="always"
                    onClick={() => setShowDescription(!showDescription)}
                  >
                    Description?
                  </Link>
                  {(showDescription || !!editData) && (
                    <TextField
                      name="description"
                      multiline
                      fullWidth
                      defaultValue={editData?.description || draft?.description}
                    />
                  )}
                  <TagInput
                    formOutput="tags"
                    separator=","
                    normalize
                    value={tags}
                  />

                  {(canCreateList || editData) && (
                    <Button variant="contained" type="submit">
                      {!editData ? "Post" : "Save Changes"}
                    </Button>
                  )}
                  {!editData && (
                    <Button
                      onClick={saveDraft}
                      variant="contained"
                      color="secondary"
                    >
                      Save Draft
                    </Button>
                  )}
                  <Button variant="outlined" onClick={() => closeCallback()}>
                    Cancel
                  </Button>
                </Stack>
              </div>
            )}
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}
