import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Avatar from "components/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import NextLink from "next/link";
import { signIn, signOut, useSession } from "next-auth/react";
import { useState } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import Paper from "@mui/material/Paper";
import HomeIcon from "@mui/icons-material/Home";
import GlobeIcon from "@mui/icons-material/Public";
import FriendsIcon from "@mui/icons-material/People";
import ChatIcon from "@mui/icons-material/Chat";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/AddCircle";
import SignOutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import SignInIcon from "@mui/icons-material/AccountCircle";
import { useRouter } from "next/router";
import { profileLink } from "lib/util";
import useSWR from "swr";
import fetcher from "lib/fetcher";
import Badge from "@mui/material/Badge";

const NavLink = ({
  children,
  onClick,
}: {
  children: any;
  onClick?: Function;
}) => (
  <Link
    onClick={onClick ? () => onClick() : () => {}}
    variant="h6"
    component="div"
    underline="none"
    color="white"
    sx={{ cursor: "pointer" }}
  >
    {children}
  </Link>
);

export default function Nav(props) {
  const router = useRouter();
  const { mobile, createList } = props;
  const { data: session, status } = useSession();
  const validSession: boolean = Boolean(status === "authenticated" && session);
  // @ts-ignore
  const sessionUser: ExtendedSessionUser = session?.user;
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [navValue, setNavValue] = useState(router.asPath);
  const {
    data: chats,
    error: chatsError,
    isLoading: isChatsLoading,
  } = useSWR(`/api/chat/unread`, fetcher);
  const NavLinks = (
    <>
      {validSession && (
        <NavLink>
          <NextLink href={`/friends`}>Friends</NextLink>
        </NavLink>
      )}
      <NavLink>
        <NextLink href={`/explore`}>Explore</NextLink>
      </NavLink>
      {validSession && (
        <NavLink>
          <NextLink href={`/bookmarks`}>Bookmarks</NextLink>
        </NavLink>
      )}

      {validSession && (
        <NavLink onClick={() => createList()}>New List +</NavLink>
      )}
    </>
  );
  const LogoLink = (
    <NextLink href={`/`}>
      <Typography
        color="white"
        fontSize={28}
        sx={{
          border: "solid 5px white",
          borderRadius: "30px",
          textIndent: -1,
          lineHeight: 1.2,
          p: "0 .35em",
        }}
      >
        5
      </Typography>
    </NextLink>
  );
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Link sx={{ mr: 4 }} component="div">
            {LogoLink}
          </Link>
          <Stack
            direction="row"
            spacing={4}
            sx={{
              display: { xs: "none", sm: "flex", flexGrow: 1 },
            }}
          >
            {NavLinks}
          </Stack>
          {mobile && <Box sx={{ flexGrow: 1 }} />}

          {!validSession && (
            <Button
              onClick={() =>
                (validSession && signOut()) ||
                signIn(undefined, { callbackUrl: "/" })
              }
              color="inherit"
            >
              {validSession ? "Sign Out" : "Sign In"}
            </Button>
          )}

          {validSession && (
            <Box sx={{ flexGrow: 0 }}>
              <Link color={"#fff"} underline="none">
                <NextLink href={`/`} passHref>
                  <Typography sx={{ display: "inline", mr: 2 }}>
                    {sessionUser?.name}
                  </Typography>
                </NextLink>
              </Link>

              <Tooltip title="Open settings">
                <IconButton
                  onClick={() => setUserMenuOpen(!userMenuOpen)}
                  sx={{ p: 0 }}
                >
                  <Avatar
                    alt={session?.user?.name}
                    src={session?.user?.image}
                  />
                </IconButton>
              </Tooltip>
              <IconButton sx={{ ml: 1 }}>
                <NextLink href={`/search`} passHref>
                  <SearchIcon htmlColor="#ffffff" />
                </NextLink>
              </IconButton>
              <Badge
                sx={{ mt: 1.5 }}
                badgeContent={chats?.unread}
                color="error"
              >
                <IconButton sx={{ ml: 1, mt: -1 }}>
                  <NextLink href={`/chat`} passHref>
                    <ChatIcon htmlColor="#ffffff" />
                  </NextLink>
                </IconButton>
              </Badge>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={userMenuOpen}
                onClose={() => setUserMenuOpen(false)}
              >
                <MenuItem>
                  <Button>
                    <Link underline="none">
                      <NextLink href={profileLink(sessionUser)}>
                        View Profile
                      </NextLink>
                    </Link>
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button>
                    <Link underline="none">
                      <NextLink href={`/profile/edit/`}>Edit Profile</NextLink>
                    </Link>
                  </Button>
                </MenuItem>

                <MenuItem>
                  <Button>
                    <Link underline="none">
                      <NextLink href={`/followers`}>Followers</NextLink>
                    </Link>
                  </Button>
                </MenuItem>

                <MenuItem>
                  <Button endIcon={<SettingsIcon />}>
                    <Link underline="none">
                      <NextLink href={"/settings"}>Settings</NextLink>
                    </Link>
                  </Button>
                </MenuItem>

                <MenuItem onClick={() => {}}>
                  <Button
                    onClick={() =>
                      (validSession && signOut()) ||
                      signIn(undefined, { callbackUrl: "/" })
                    }
                    endIcon={validSession ? <SignOutIcon /> : <SignInIcon />}
                    color="inherit"
                  >
                    {validSession ? "Sign Out" : "Sign In"}
                  </Button>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {mobile && validSession && (
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1100,
          }}
          elevation={3}
        >
          <BottomNavigation
            value={navValue}
            onChange={(e, val) => {
              if (val === "new") return;
              setNavValue(val);
            }}
          >
            {[
              { label: "Home", icon: <HomeIcon />, value: "/" },
              {
                label: "Bookmarks",
                icon: <BookmarkIcon />,
                value: "/bookmarks",
              },
              { label: "Explore", icon: <GlobeIcon />, value: "/explore" },
              { label: "Friends", icon: <FriendsIcon />, value: "/friends" },
            ].map((bnav, index) => (
              <BottomNavigationAction
                key={index}
                sx={{ minWidth: "40px" }}
                value={bnav.value}
                onClick={() => router.push(bnav.value)}
                label={bnav.label}
                icon={bnav.icon}
              />
            ))}
            <BottomNavigationAction
              value="new"
              onClick={() => createList()}
              label="New List"
              icon={<AddIcon />}
            />
          </BottomNavigation>
        </Paper>
      )}
    </div>
  );
}
