function profileLink(profile) {
  const profileSlug = profile?.name
    ?.replace(/[^a-zA-Z9-9]/g, "-")
    .toLowerCase();
  return `/profile/${profileSlug}/${profile.id}`;
}
function slugify(str) {
  return str
    .replace(/[^a-zA-Z0-9]/g, "-")
    .replaceAll("--", "-")
    .toLowerCase();
}
function listLink(list) {
  const dayjs = require("dayjs");
  const listSlug = slugify(
    list?.title || dayjs(list.createdAt).format("ddd-M-D"),
  );
  return `/list/${listSlug}/${list.id}`;
}
export { profileLink, slugify, listLink };
