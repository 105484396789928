import { start } from "lib/bugsnag";
start();
import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from "@mui/material/Stack";
import { useRouter } from "next/router";
import InnerApp from "components/InnerApp";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "styles/globals.css";
import Head from "next/head";

let caughtError = null;

Bugsnag.addOnError((event) => {
  caughtError = event;
});

const ErrorBoundary =
  Bugsnag.getPlugin("react")?.createErrorBoundary(React) ||
  function (props) {
    return <>{props.children}</>;
  };

const ErrorView = ({
  clearError,
  error,
  info,
}: {
  clearError: React.MouseEventHandler<HTMLButtonElement>;
  error: Error;
  info: React.ErrorInfo;
}) => {
  const router = useRouter();
  const errorString = JSON.stringify(error);
  const infoString = JSON.stringify(info);
  const caughtErrorString = JSON.stringify(caughtError);

  return (
    <CssBaseline>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <Box display={"flex"} flexDirection={"column"} minHeight={"100vh"}>
        <Box
          alignContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
          justifyContent={"center"}
        >
          <h1>An Error Occured</h1>
          <p>
            Don't worry, we've been notified.
            <br />
            We'll be working to fix this error ASAP.
          </p>
          <Button variant="outlined" onClick={() => router.reload()}>
            Try Again?
          </Button>
          <p>or</p>
          <Button
            variant="outlined"
            onClick={(e) => {
              fetch("/api/clearcookies").finally(() => {
                clearError(e);
                router.push("/");
                router.reload();
              });
            }}
          >
            Log out
          </Button>
        </Box>
        <Stack spacing={2} mt={2} fontSize={9} p={2}>
          <h2>Error info:</h2>

          <div>
            {errorString?.split("\\n").map((s) => (
              <div>{s}</div>
            ))}
          </div>
          <div>
            {infoString?.split("\\n").map((s) => (
              <div>{s}</div>
            ))}
          </div>
          <div>
            {caughtErrorString?.split(/[\n|,]/).map((s) => (
              <div>{s}</div>
            ))}
          </div>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(
                `${errorString} \n\n ${infoString} \n\n ${caughtErrorString}`,
              );
            }}
            variant="contained"
          >
            Copy Error Text
          </Button>
        </Stack>
      </Box>
    </CssBaseline>
  );
};

export default function App({ Component, pageProps, err }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorView}>
      <InnerApp Component={Component} pageProps={pageProps} err={err} />
    </ErrorBoundary>
  );
}
