import Head from "next/head";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Nav from "./Nav";
import { SessionProvider } from "next-auth/react";
import { useSession } from "next-auth/react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CreateList from "components/CreateList";
import useSWR from "swr";
import fetcher from "lib/fetcher";
import dayjs from "dayjs";

import { useState, useEffect } from "react";
import { blue, yellow, grey, red, green } from "@mui/material/colors";

export default function InnerApp({ Component, pageProps, err }) {
  const theme = createTheme({
    palette: {
      secondary: {
        main: grey[400], //"#f44336",
      },
      primary: {
        main: "#000", //"#1565c0",
        light: blue[50],
      },
      success: {
        main: green[500],
        light: green[100],
      },
      error: {
        main: red[500],
        light: red[300],
      },
      warning: {
        main: yellow[500],
        light: yellow[100],
      },
    },
  });
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(1000);
  const [createListOpen, setCreateListOpen] = useState(false);
  const [listToEdit, setListToEdit] = useState(null);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    status?: "success" | "warning" | "error";
    message?: string;
    autoHideDuration?: number;
  }>({
    open: false,
    status: null,
    message: null,
  });
  function openSnackBar(cfg) {
    setSnackbar({ ...cfg, open: true });
  }
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  let mobile = width <= 600;

  const { data } = useSWR("/api/list/mine", fetcher);
  const lastList = data?.sort((a, b) =>
    a.createdAt > b.createdAt ? -1 : 1,
  )[0];
  const lastListDate = lastList?.createdAt ? dayjs(lastList?.createdAt) : null;
  const now = dayjs();
  const diff = lastListDate ? now.diff(dayjs(lastListDate).hour(0), "day") : 7;
  const canCreateList = diff >= 7;

  useEffect(() => {
    if (data?.length) {
      if (canCreateList && typeof Notification !== "undefined") {
        Notification?.requestPermission().then((result) => {
          if (result.toLowerCase() === "granted") {
            const notification = new Notification("Five Things", {
              body: "It's time to make your list for this week!",
              icon: "/logo.png",
            });
            notification.onclick = () => {
              setCreateListOpen(true);
            };
          }
        });
      }
    }
  }, [data]);

  return (
    <CssBaseline>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <SessionProvider>
        <ThemeProvider theme={theme}>
          <Nav mobile={mobile} createList={() => setCreateListOpen(true)} />

          <Modal open={loading} onClose={() => setLoading(false)}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress />
            </Box>
          </Modal>
          <CreateList
            alert={openSnackBar}
            loading={setLoading}
            canCreateList={canCreateList}
            open={createListOpen}
            closeCallback={() => {
              setCreateListOpen(false);
              setListToEdit(null);
            }}
            edit={!!listToEdit}
            editData={listToEdit}
          />
          <Snackbar
            open={snackbar.open}
            autoHideDuration={snackbar.autoHideDuration || 6000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              severity={snackbar.status}
              sx={{ width: "100%" }}
            >
              <AlertTitle sx={{ textTransform: "capitalize" }}>
                {snackbar.status}
              </AlertTitle>
              {snackbar.message}
            </Alert>
          </Snackbar>
          <Container maxWidth="md">
            <div className="main">
              {Component.auth ? (
                <Auth role={Component.auth}>
                  <Component
                    alert={openSnackBar}
                    mobile={mobile}
                    {...pageProps}
                    loading={setLoading}
                    createList={() => setCreateListOpen(true)}
                    editList={(list) => {
                      setListToEdit(list);
                      setCreateListOpen(true);
                    }}
                  />
                </Auth>
              ) : (
                <Component
                  err={err}
                  alert={openSnackBar}
                  mobile={mobile}
                  {...pageProps}
                  createList={() => setCreateListOpen(true)}
                  editList={(list) => {
                    setListToEdit(list);
                    setCreateListOpen(true);
                  }}
                  loading={setLoading}
                />
              )}
              {mobile && <Box p={5} />}
            </div>
          </Container>
        </ThemeProvider>
      </SessionProvider>
    </CssBaseline>
  );
}

// import ErrorPage from "next/error";
function Auth({ children, role }) {
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { data, status } = useSession({ required: true });
  if (status === "loading") {
    return <div>Loading...</div>;
  }
  // if (role !== true) {
  //   //@ts-ignore
  //   if (data.user.role !== role && data.user.role !== "admin") {
  //     return <ErrorPage statusCode={401} title="Not authorized" />;
  //   }
  // }
  return children;
}
