import Avatar from "@mui/material/Avatar";
import { CldImage } from "next-cloudinary";
export default function MyAvatar({
  src,
  sx,
  alt,
}: {
  src: string;
  sx?: any;
  alt?: string;
}) {
  if (src?.includes("res.cloudinary.com")) {
    return (
      <div
        style={{
          width: 40,
          height: 40,
          ...sx,
          borderRadius: "50%",
          overflow: "hidden",
          display: "flex",
          position: "relative",
          flexShrink: 0,
          alignItems: "center",
        }}
      >
        <CldImage
          src={src?.split("/").pop()}
          width={sx?.width || 40}
          height={sx?.height || 40}
          crop="thumb"
          quality="90"
          loading="lazy"
          alt={alt || "avatar"}
          gravity="faces"
        />
      </div>
    );
  }
  return <Avatar src={src} sx={sx} />;
}
