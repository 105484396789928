import { CldImage, getCldImageUrl } from "next-cloudinary";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";

export default function Image({
  src,
  alt,
  width: customWidth,
}: {
  src: string;
  alt?: string;
  width?: number;
}) {
  const [zoom, setZoom] = useState(false);
  const [width, setWidth] = useState(1000);
  const size = 200;
  const cloudinarysrc = src?.split("/").pop();
  const zoomsrc = getCldImageUrl({
    src: cloudinarysrc,
    width: width,
    format: "webp",
  });

  const mobile = width <= 600;
  const calculatedWidth = mobile ? width - 70 : 700;

  useEffect(() => {
    setWidth(window.innerWidth);
  });

  return (
    <div>
      {zoom && (
        <Dialog sx={{ m: 0 }} open={true} onClose={() => setZoom(false)}>
          <img width="100%" src={zoomsrc} />
        </Dialog>
      )}
      <Paper
        elevation={5}
        sx={{
          m: 0,
          mt: 2,
          width: calculatedWidth,
          height: size,
          cursor: "pointer",
        }}
        onClick={() => setZoom(true)}
      >
        <CldImage
          src={cloudinarysrc}
          alt={alt || "image"}
          crop="fill"
          width={calculatedWidth}
          height={size}
          format="webp"
        />
      </Paper>
    </div>
  );
}
