import Chip from "@mui/material/Chip";
import Textfield from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import { urlSeparator } from "lib/constants";

export default function TagInput(props) {
  const { normalize, formOutput, name, value, separator } = props;
  const valueFromProps = Array.isArray(value)
    ? value?.map((t) => t)
    : value?.length > 0
    ? value?.split(separator || urlSeparator)
    : [];
  const [input, setInput] = useState("");
  const [tags, setTags] = useState(valueFromProps);
  useEffect(() => {
    setTags(valueFromProps);
  }, [value]);

  function parseTagInput() {
    const tagsFromInput = input.split(/[;,\s]/g);
    let tagsToSave: string[] = [];
    let error = false;
    tagsFromInput.forEach((value) => {
      value = !normalize
        ? value
        : value?.replaceAll(/[^a-zA-Z0-9]/g, "").trim();
      if (value.length < 3) return;
      if (value && !tags?.includes(value)) {
        tagsToSave.push(value);
      }
    });
    if (!error) {
      setTags([...tags, ...tagsToSave]);
      setInput("");
    }
  }
  function handleDelete(tag) {
    setTags((tags) => tags.filter((e) => e !== tag));
  }
  return (
    <Stack>
      {formOutput && (
        <input
          type="hidden"
          name={formOutput}
          value={tags.join(separator || urlSeparator)}
        />
      )}
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{
          mb: tags.length > 0 ? 4 : 0,
        }}
      >
        {tags.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            sx={{ mr: 1, mt: 1 }}
            onDelete={() => handleDelete(tag)}
          />
        ))}
      </Stack>
      <Textfield
        value={input}
        onChange={(e) => setInput(e.target.value)}
        label={name || "Tags"}
        onBlur={(e) => parseTagInput()}
        onKeyDown={(e) => {
          if (input.length > 0 && ["Tab", " ", "Enter", ","].includes(e.key)) {
            e.preventDefault();
            parseTagInput();
          }
        }}
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
      />
    </Stack>
  );
}
